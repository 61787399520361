import request from '@/utils/request'

// 我的邀请
export function listMyinvitation(query) {
    return request({
        url: '/bm/signUp/my-invitation-list',
        method: 'get',
        params: query
    })
}
// 发送取消报名验证码地址
export function cancelSignCode(signId) {
    return request({
        url: '/bm/signUp/send/cancelVerificationCode/' + signId,
        method: 'get',
    })
}
// 取消报名
export function cancelSign(signId, code) {
    return request({
        url: '/bm/signUp/cancelSignUp/' + signId + '/' + code,
        method: 'get',
    })
}