<template>
  <div class="plat-course-jiancha">
    <!-- 空 -->
    <div class="pt-line"></div>
    <div class="p-jiancha-box1">
      <span>佰科聘用码：{{ jobNumber }}</span>
      <span>当前得分：{{ score }}</span>
    </div>
    <!-- 空 -->
    <div class="pt-line2"></div>
    <div class="p-jiancha-box2">
      <!-- 分值明细 -->
      <div class="deit">
        <div class="crumbs">
          <div class="cantainer">
            <!-- <el-table
              style="width: 100%"
              :data="
                inviteRecordList.slice(
                  (currentPage - 1) * pageSize,
                  currentPage * pageSize
                )
              "
            > -->
            <el-table style="width: 100%" :data="inviteRecordList">
              <!-- <el-table-column type="index" width="50"> </el-table-column> -->
              <!-- <el-table-column
                label="分值类型"
                prop="scoreType"
                :formatter="scoreTypeFormat"
                width="247.8"
              > -->
              <el-table-column
                label="分值类型"
                prop="scoreType"
                :formatter="scoreTypeFormat"
                width="206.5"
              >
              </el-table-column>
              <el-table-column label="时间" prop="scoreDate" width="206.5">
              </el-table-column>

              <el-table-column label="增加分数" prop="alterationScore" width="206.5">
                <!-- <template slot-scope="scope"> {{ scope.$index }} </template> -->
                <!-- <template slot-scope="scope">
                            <span :class="'status-' + scope.row.status">{{
                    selectDictLabel(statusOptions, scope.row.status)
                  }}</span>
                          </template
                > -->
                <template slot-scope="scope">
                  <span v-if="scope.row.alterationScore > 0"
                    >+ {{ scope.row.alterationScore }}</span
                  >
                  <span v-else>{{ scope.row.alterationScore }}</span>
                </template>
                <!-- <template slot-scope="scope" v-else>
                  {{ scope.row.alterationScore }}
                </template> -->
              </el-table-column>
              <el-table-column label="增加后分数" prop="newScore" width="206.5">
              </el-table-column>
              <el-table-column label="备注" prop="remark" width="206.5">
              </el-table-column>
              <el-table-column label="操作人" prop="operator" width="206.5">
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="pt-pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="inviteRecordList.length"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listInspectorScoreDetail,
  getinspectorMy,
} from "@/api/bm/inspectorScoreDetail.js";
import { getConfigKey } from "@/api/system/config.js";
export default {
  components: {},
  data() {
    return {
      // 邀请记录
      inviteRecordList: [],
      alterationScore: "",
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        inspectorId: undefined,
      },
      // 工号
      jobNumber: "",
      // 分数
      score: "",
      // 得分类别字典
      scoreTypeOptions: [],
      //
      list: [],
      //
      currentPage: 1, //初始页
      pageSize: 10, //    每页的数据
      userList: [],
    };
  },
  created() {
    this.queryParams.inspectorId = localStorage.getItem("inspectorId");
    this.getInviteRecordList();
    this.getDicts("inspector_score_type").then((response) => {
      this.scoreTypeOptions = response.data;
    });
  },
  methods: {
    // 获取邀请记录列表 分值明细 检查员登录信息
    getInviteRecordList(page) {
      listInspectorScoreDetail(this.queryParams).then((res) => {
        console.log("hh", res);
        this.inviteRecordtotal = res.total;
        this.inviteRecordList = res.rows;
      });
      // 获取检查员登录信息
      getinspectorMy().then((res) => {
        console.log("获取检查员登录信息", res);

        this.jobNumber = res.data.jobNumber;
        this.score = res.data.score.score;
      });
    },
    // 分数类别字典翻译
    scoreTypeFormat(row, column) {
      return this.selectDictLabel(this.scoreTypeOptions, row.scoreType);
    },

    // 初始页currentPage、初始每页数据数pageSize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      console.log(this.pageSize); //每页下拉显示数据
      this.queryParams.pageSize = size || "1";
      this.getInviteRecordList();
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
      this.queryParams.pageNum = currentPage || "1";
      this.getInviteRecordList();
    },
    handleUserList() {},

    // 获取记录列表
    getList() {
      listrecord().then((res) => {
        console.log("请获取记录列表成功");
        console.log(res);
        this.list = res.rows;
      });
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 项目详情
    handleEdit(index, row) {
      console.log("项目详情888888index", index);
      console.log("项目详情888888row", row);
      const projectID = row.recruitProjectId;
      this.$router.push({ path: "/recruitsDetail", query: { id: projectID } });
    },
    // 取消投递
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.plat-course-jiancha {
  width: 100%;
  height: auto;
  // border: 1px solid tan;
  .pt-line {
    width: 100%;
    height: 26px;
    background: #f8f8f8;
  }
  .pt-line2 {
    width: 100%;
    height: 4px;
    background: #f8f8f8;
  }
  .p-jiancha-box1 {
    width: 95%;
    height: 57px;
    background: #ffffff;
    // box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
  }
  .p-jiancha-box2 {
    width: 1239px;
    height: auto;
    margin: 45px auto 0px auto;
    // border: 1px solid tan;
  }
  ::v-deep .el-table th {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    background-color: #eaeaea !important;
    // background: #eaeaea;
    font-weight: 500;
  }
  ::v-deep .el-table th > .cell {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
  .pt-pagination {
    padding: 50px 0px;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #ffffff;
      background: rgba(189, 195, 199, 1);
    }
    ::v-deep .el-pagination.is-background .btn-next,
    ::v-deep .el-pagination.is-background .btn-prev,
    ::v-deep .el-pagination.is-background .el-pager li {
      margin: 0 6px;
      // background-color: #f4f4f5;
      // color: #606266;
      min-width: 39px;
      height: 39px;
      border-radius: 2px;
      line-height: 39px;
    }
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: rgba(17, 99, 235, 1);
      color: #fff;
    }
    ::v-deep .el-pagination button,
    ::v-deep .el-pagination span:not([class*="suffix"]) {
      display: inline-block;
      font-size: 13px;
      min-width: 35.5px;
      height: 39px;
      line-height: 39px;
      vertical-align: top;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}
</style>
