<template>
  <div class="plat-course-count">
    <!-- 空 -->
    <div class="pt-line"></div>

    <div class="plat-count-box">
      <div>
        <!-- <el-select
          v-model="querystatistics.year"
          placeholder="请选择年份"
          clearable
          @change="changestatisticsYear"
        >
          <el-option
            v-for="(item, index) in yearList"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select> -->
        <el-select
          v-model="querystatistics.year"
          placeholder="请选择年份"
          @change="changestatisticsYear"
        >
          <el-option
            v-for="dict in yearList"
            :key="dict.dictValue"
            :label="dict.dictLabel"
            :value="dict.dictValue"
          />
        </el-select>
        <!-- <div class="plat-count-box2" ref="statistics"></div> -->
      </div>
      <div class="plat-count-box2" ref="statistics"></div>
      <!-- <div class="plat-count-box1">
        <el-select v-model="region" placeholder="请选择年份">
          <el-option label="2020年" value="shanghai"></el-option>
          <el-option label="2021年" value="beijing"></el-option>
        </el-select>
      </div>

      <div class="pt-danwei">分数（分）</div>

      <div class="plat-count-box2" id="numTb"></div> -->
    </div>
  </div>
</template>

<script>
import { getstatistics } from "@/api/bm/inspectorScoreDetail.js";
import { getConfigKey } from "@/api/system/config.js";
import { getCurrentQuarter } from "@/utils/baike";
export default {
  components: {},
  data() {
    return {
      // 统计弹出层时间
      // querystatistics: {
      //   year: new Date().getFullYear(),
      //   inspectorId: "",
      // },
      // 统计弹出层时间
      querystatistics: {
        // year: new Date().getFullYear(),
        year: getCurrentQuarter(),
        inspectorId: "",
      },
      // 统计数据
      statisticsTime: [],
      statisticsData: [],
      yearList: [],
      region: "2021年",
    };
  },
  created() {
    this.querystatistics.inspectorId = localStorage.getItem("inspectorId");
    this.getstatisticsList();
  },
  methods: {
    // 获取统计数据
    getstatisticsList() {
      getstatistics(this.querystatistics).then((res) => {
        this.statisticsData = res.data.values;
        this.statisticsTime = res.data.keys;
        console.log("下边", this.statisticsTime);
        this.statisticsloading = false;
        this.$nextTick(() => {
          this.drawLine();
        });
      });
      // getstatistics(this.querystatistics).then((res) => {
      //   this.statisticsData = Object.values(res.data);

      //   this.$nextTick(() => {
      //     this.drawLine();
      //   });
      // });
    },
    // 统计图表绘制
    drawLine() {
      let myChart = this.$echarts.init(this.$refs.statistics);
      // 绘制图表
      myChart.setOption({
        // tooltip: {},
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          data: this.statisticsTime,
        },
        yAxis: {
          type: "value",
          name: ["单位/分"],
        },
        series: [
          {
            name: "得分统计",
            type: "bar",
            data: this.statisticsData,
            barWidth: 36,
            label: {
              normal: {
                show: true,
                position: "top",
                color: "#000",
                formatter: (param) => {
                  return param.data + "分";
                },
              },
            },
            itemStyle: {
              normal: {
                color: "#488CF0",
              },
            },
            // 给柱子加背景色
            // showBackground: true,
            // backgroundStyle: {
            //     color: 'rgba(22,208,144,0.15)'
            // },
          },
        ],
      });
    },
    // 获取年份
    // 获取年份
    getyearList() {
      getConfigKey("inspector.start.year").then((res) => {
        let startyear = res.msg.split("-")[0];

        let date = new Date();
        console.log(startyear, getCurrentQuarter().split("-")[1], date.getFullYear());
        for (let i = startyear; i <= date.getFullYear(); i++) {
          if (i != date.getFullYear()) {
            console.log("等于");
            for (let j = 1; j <= 4; j++) {
              this.yearList.push({
                dictValue: `${i}-${j}`,
                dictLabel: `${i}年${j}季度`,
              });
            }
          } else {
            for (let j = 1; j <= getCurrentQuarter().split("-")[1]; j++) {
              this.yearList.push({
                dictValue: `${i}-${j}`,
                dictLabel: `${i}年${j}季度`,
              });
            }
          }
        }
        console.log(this.yearList);
      });

      // this.getDicts("inspector_year_quarter").then(response => {
      //   this.yearList = response.data;
      // });
    },
    // getyearList() {
    //   getConfigKey("inspector.start.year").then((res) => {
    //     let startyear = parseInt(res.msg);
    //     let date = new Date();
    //     console.log("获取年份", res);
    //     for (let i = startyear; i <= date.getFullYear(); i++) {
    //       this.yearList.push(i);
    //     }
    //   });
    // },
    // 改变统计年份
    changestatisticsYear() {
      // this.statisticsloading = true;
      // this.statisticsData = [1, 2, 3, 4];
      this.getstatisticsList();
      // this.getyearList();
    },
    // 统计
    countChart() {
      let myChart = this.$echarts.init(document.getElementById("numTb"));
      // 常住人口
      //   let dom = document.getElementById("numTb");
      //   let myChart = echarts.init(dom);
      let app = {};
      //   let option = null;
      let option = {
        color: ["#3398DB"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },

        xAxis: [
          {
            type: "category",
            data: ["第一季度", "第二季度", "第三季度", "第四季度"],
            axisTick: {
              alignWithLabel: true,
            },
            axisLabel: {
              color: "#000000",
              fontStyle: "normal",
              interval: 0,
              fontSize: 14,
            },
            //设置轴线的属性
            axisLine: {
              lineStyle: {
                color: "#0079FF",
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              color: "#000000",
              fontStyle: "normal",
              fontSize: 14,
            },
            //设置轴线的属性
            axisLine: {
              lineStyle: {
                color: "#0079FF",
                width: 1, //这里是为了突出显示加上的
              },
            },
            splitLine: {
              //网格线
              lineStyle: {
                color: "#666",
                type: "dashed", //设置网格线类型 dotted：虚线   solid:实线
              },
              show: true, //隐藏或显示
            },
          },
        ],
        series: [
          {
            name: "",
            type: "bar",
            barWidth: "20%",
            data: [10, 20, 15, 100],
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 14,
                  },
                },
                // color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 1, color: "#357EEA" }, //柱图渐变色
                  { offset: 0.5, color: "#357EEA" }, //柱图渐变色
                  { offset: 0, color: "#357EEA" }, //柱图渐变色
                ]),
              },
            },
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  mounted() {
    this.getyearList();
    // this.countChart();
  },
};
</script>

<style scoped lang="scss">
.plat-course-count {
  width: 100%;
  height: auto;
  // border: 1px solid tan;
  .pt-line {
    width: 100%;
    height: 26px;
    background: #f8f8f8;
  }

  .plat-count-box {
    width: 1239px;
    height: auto;
    margin: 45px auto 0px auto;
    position: relative;
    // border: 1px solid tan;
    .plat-count-box1 {
      width: 100%;
      height: 40px;
      // border: 1px solid tan;
      ::v-deep .el-input--suffix .el-input__inner {
        padding-right: 30px;
        width: 165px;
        height: 35px;
        line-height: 35px;
      }
    }
    .plat-count-box2 {
      width: 1000px;
      height: 500px;
      // border: 1px solid tan;
      position: relative;
    }
    .pt-danwei {
      position: absolute;
      top: 66px;
      left: 27px;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #000000;
      z-index: 99;
    }
  }
  ::v-deep .el-input--suffix .el-input__inner {
    padding-right: 30px;
    width: 165px;
    height: 35px;
    line-height: 35px;
  }
}
</style>
