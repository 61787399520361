<template>
  <div class="plat-course-jiancha">
    <!-- 空 -->
    <div class="pt-line"></div>
    <div class="pt-line2"></div>
    <div class="p-jiancha-box2">
      <!-- form表单 -->
      <div class="invitation-form">
        <el-form ref="queryParams" :model="queryParams" label-width="80px">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="queryParams.name" placeholder="请输入姓名"></el-input>
          </el-form-item>
          <el-form-item label="课程名称" prop="titleLike">
            <el-input
              v-model="queryParams.titleLike"
              placeholder="请输入课程名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="报名状态" prop="status">
            <el-select
              v-model="queryParams.status"
              placeholder="请选择报名状态"
              clearable
              size="small"
            >
              <el-option
                v-for="dict in signupOptions"
                :key="dict.dictValue"
                :label="dict.dictLabel"
                :value="dict.dictValue"
              />
            </el-select>
          </el-form-item>
          <!--  -->
          <el-form-item label="报名时间" prop="timeValue">
            <el-date-picker
              v-model="timeValue"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="changetimeValue"
              value-format="yyyy-MM-dd"
              :clearable="false"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="querySubmit">查询</el-button>
            <el-button type="primary" @click="resetForm('queryParams')">重置</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!-- table -->
      <div class="deit">
        <div class="crumbs">
          <div class="cantainer">
            <el-table
              style="width: 100%"
              border
              :data="inviteRecordList"
              v-loading="Inviteloading"
            >
              <el-table-column label="序号" type="index"> </el-table-column>
              <el-table-column label="姓名" prop="name"> </el-table-column>
              <el-table-column label="手机号" prop="phone"> </el-table-column>

              <el-table-column label="公司名称" prop="unit"> </el-table-column>
              <el-table-column label="课程名称" prop="title"> </el-table-column>
              <el-table-column label="报名时间" prop="bmTime"> </el-table-column>
              <el-table-column label="状态" prop="status">
                <template slot-scope="scope">
                  <span v-if="scope.row.status == 0">未上传凭证</span>
                  <span v-else-if="scope.row.status == 1">已确认收款</span>
                  <span v-else-if="scope.row.status == 2">已上传凭证</span>
                  <span v-else-if="scope.row.status == 3">已开发票</span>
                  <span v-else-if="scope.row.status == 4">已驳回</span>
                  <span v-else>已取消</span>
                </template>
              </el-table-column>
              <!-- <el-table-column label="操作" prop="operator" width="169.85">
                <template slot-scope="scope">
                  <span class="detail" @click="dialogVisiblebtn($event)">详情</span>
                </template>
              </el-table-column> -->
            </el-table>
            <!-- 详情 -->
            <el-dialog
              title=""
              :visible.sync="dialogVisible"
              width="35%"
              :append-to-body="true"
              :before-close="handleClose"
              :close-on-click-modal="false"
            >
              <!--  -->
              <div class="class-sucess">
                <div class="class-sucessbox">
                  <!-- close -->
                  <img class="class-close" src="@/assets/image/close.png" alt="" />
                  <!-- 填写报名信息 -->
                  <div class="class-top">用户信息</div>
                  <!-- 个人信息 -->
                  <div class="info-title">
                    <div class="info-title_L">秦风</div>
                    <div class="info-title_R">
                      <div class="info-title_R1">15123017665</div>
                      <div class="info-title_R2">广州佰科教育</div>
                      <div class="info-title_R3">
                        儿童玩具产品受理人员显示完全课程信息
                      </div>
                    </div>
                  </div>
                  <!-- 进度信息 -->
                  <div class="info-progress">
                    <div class="class-box-1L-2 sign-pro-2">
                      <el-steps
                        direction="vertical"
                        finish-status="success"
                        :active="dealStatus(info.status)"
                      >
                        <el-step title="提交报名信息">
                          <template slot="description">
                            <div class="sign-pro-2box-1">报名时间：{{ info.bmTime }}</div>
                          </template>
                        </el-step>
                        <el-step title="上传转账凭证">
                          <template slot="description">
                            <div class="sign-pro-2box-2">
                              <div class="sign-pro-2box-2a" v-show="info.evaluationTime">
                                上传时间：{{ info.evaluationTime }}
                              </div>
                              <!-- <div class="sign-pro-2box-2a">
                                <span class="x-span">*</span>
                                转账成功后请在此上传您的转账凭证照片
                              </div> -->
                              <div class="sign-pro-2box-2a">上传内容</div>

                              <el-upload
                                class="avatar-uploader"
                                action="/dev-api/common/upload"
                                :show-file-list="false"
                                :on-success="handleAvatarSuccess"
                              >
                                <img :src="info.evaluation" alt />
                                <!-- <img
                                  v-show="info.evaluation == null"
                                  class="tx-img"
                                  src="@/assets/image/upload.png"
                                  alt=""
                                /> -->
                                <!-- <div
                                  class="tx-img tx-img2"
                                  v-show="info.evaluation == null"
                                >
                                  上传凭证
                                </div> -->
                              </el-upload>

                              <div class="sign-pro-2box-2a" v-show="info.status == '4'">
                                已被驳回，请重新上传凭证
                              </div>
                              <div class="sign-pro-2box-2a" v-show="info.status == '4'">
                                驳回原因：{{ info.rejectReason }}
                              </div>
                            </div>
                          </template>
                        </el-step>

                        <el-step title="平台已确认收款">
                          <template slot="description">
                            <div class="sign-pro-2box-2">
                              <div
                                class="sign-pro-2box-2a"
                                v-show="info.affirmTime != null"
                              >
                                操作时间：{{ info.affirmTime }}
                              </div>
                              <div
                                class="sign-pro-2box-2a sign-pro-2box-2b"
                                v-show="info.affirmTime != null"
                              >
                                <p>需按照指引上传企业开票信息，<el-link type="primary" style="font-size: 15px" @click="handleDownload">查看详情</el-link></p>
                                <p>增值税专用发票/普通发票，在培训结束后10-15个工作日寄出</p>
                              </div>
                            </div>
                          </template>
                        </el-step>
                        <el-step title="平台发票已寄出">
                          <template slot="description">
                            <div class="sign-pro-2box-2">
                              <div
                                class="sign-pro-2box-2a"
                                v-show="info.finishTime != null"
                              >
                                操作时间：{{ info.finishTime }}
                              </div>
                              <div
                                class="sign-pro-2box-2a"
                                v-show="info.waybillNum != null"
                              >
                                <span class="kd-s1">发票快递单号：</span
                                ><span class="kd-s1" id="content">{{
                                  info.waybillNum
                                }}</span
                                ><span
                                  class="copyTrackingNumber copy-btn"
                                  data-clipboard-target="#content"
                                  @click="copyTrackingNumber"
                                  >复制</span
                                >
                              </div>
                              <div
                                class="sign-pro-2box-2a"
                                v-show="info.courierCompany != null"
                              >
                                快递公司：{{ info.courierCompany }}
                              </div>
                            </div>
                          </template>
                        </el-step>
                      </el-steps>
                    </div>
                  </div>
                  <!-- 确认 -->
                  <div class="suc-btn" @click="submitForm1()">确认</div>
                </div>
              </div>
            </el-dialog>
            <!-- 分页 -->
            <div class="pt-pagination">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 20, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="inviteRecordtotal"
              >
              </el-pagination>
              <!-- <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[5, 10, 20, 40]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="inviteRecordList.length"
              >
              </el-pagination> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  listInspectorScoreDetail,
  getinspectorMy,
} from "@/api/bm/inspectorScoreDetail.js";
import { getConfigKey } from "@/api/system/config.js";
import { getCourseJoinUser } from "@/api/bm/course";
import { listMyinvitation } from "@/api/bm/myinvitation";
import {isWeiXin} from "@/utils/baike";
import {download} from '@/common/utils/tools.js'
export default {
  components: {},
  data() {
    return {
      // 邀请记录
      inviteRecordList: [],
      inviteRecordtotal: "",
      alterationScore: "",
      timeValue: "",
      dialogVisible: false,
      // 邀请记录遮罩层
      Inviteloading: true,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        titleLike: "", //课程名称
        status: "",
        bmTimeGte: "",
        bmTimeLte: "",
      },
      info: {},
      formMess: {
        // id: localStorage.getItem("signId2"),
        id: localStorage.getItem("signId"),
        // id: 0,
        imageUrl: null, //上传图片路径imageUrl
      },
      wayOptions: [],
      signupOptions: [],
      statusOptions: [
        {
          dictValue: 0,
          dictLabel: "待上传凭证",
        },
        {
          dictValue: 1,
          dictLabel: "待寄出发票",
        },
        {
          dictValue: 2,
          dictLabel: "待确认收款",
        },
      ],
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      // 工号
      jobNumber: "",
      // 分数
      score: "",
      // 得分类别字典
      scoreTypeOptions: [],
      //
      list: [],
      //
      currentPage: 1, //初始页
      pageSize: 10, //    每页的数据
      userList: [],
    };
  },
  created() {
    this.queryParams.inspectorId = localStorage.getItem("inspectorId");
    this.getInviteRecordList();
    this.getDicts("inspector_score_type").then((response) => {
      this.scoreTypeOptions = response.data;
    });
    //
    this.getInfo();
    this.getDicts("bm_course_way").then((res) => {
      this.wayOptions = res.data;
    });
    // 报名状态
    this.getDicts("bm_sign_up_status").then((res) => {
      this.signupOptions = res.data;
      console.log("报名状态", res.data);
    });
    // 获取检查员登录信息
    getinspectorMy().then((res) => {
      console.log("获取检查员登录信息", res);

      this.jobNumber = res.data.jobNumber;
      this.score = res.data.score.score;
    });
  },
  methods: {
    async handleDownload() {
      const {msg} = await this.getConfigKey('invoiceGuide')
      download(msg,'关于开具增值税发票的操作指引')
    },
    // 详情弹框
    dialogVisiblebtn: function (e) {
      this.dialogVisible = true;
    },
    // 日期选择
    changetimeValue() {
      this.queryParams.bmTimeGte = this.timeValue[0];
      this.queryParams.bmTimeLte = this.timeValue[1];
      console.log("日期1", this.queryParams.bmTimeGte);
      console.log("日期2", this.queryParams.bmTimeLte);
    },
    // 我的邀请
    getInviteRecordList(page) {
      this.Inviteloading = true;
      listMyinvitation(this.queryParams).then((res) => {
        this.Inviteloading = false;
        console.log("hh我的邀请", res);
        this.inviteRecordtotal = res.total;
        this.inviteRecordList = res.rows;
      });
      // 获取检查员登录信息
      // getinspectorMy().then((res) => {
      //   console.log("获取检查员登录信息", res);

      //   this.jobNumber = res.data.jobNumber;
      //   this.score = res.data.score.score;
      // });
    },
    // 分数类别字典翻译
    scoreTypeFormat(row, column) {
      return this.selectDictLabel(this.scoreTypeOptions, row.scoreType);
    },
    // 查询
    querySubmit() {
      this.getInviteRecordList();
    },
    resetForm(formName) {
      console.log("重置", formName);
      this.timeValue = "";
      this.queryParams.bmTimeGte = "";
      this.queryParams.bmTimeLte = "";
      this.$refs[formName].resetFields();
      this.getInviteRecordList();
    },

    // 初始页currentPage、初始每页数据数pageSize和数据data
    handleSizeChange: function (size) {
      this.pageSize = size;
      console.log(this.pageSize); //每页下拉显示数据
      this.queryParams.pageSize = size || "1";
      this.getInviteRecordList();
    },
    handleCurrentChange: function (currentPage) {
      this.currentPage = currentPage;
      console.log(this.currentPage); //点击第几页
      this.queryParams.pageNum = currentPage || "1";
      this.getInviteRecordList();
    },
    handleUserList() {},

    // 获取记录列表
    getList() {
      listrecord().then((res) => {
        console.log("请获取记录列表成功");
        console.log(res);
        this.list = res.rows;
      });
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 项目详情
    handleEdit(index, row) {
      console.log("项目详情888888index", index);
      console.log("项目详情888888row", row);
      const projectID = row.recruitProjectId;
      this.$router.push({ path: "/recruitsDetail", query: { id: projectID } });
    },
    //

    //
    getInfo() {
      // let id = this.$route.query.id;
      let id = 13;
      getCourseJoinUser(id).then((res) => {
        this.info = res.data;
        console.log("我的报名888888");
        localStorage.setItem("signId", res.data.signId);
        this.formMess.id = res.data.signId;
        console.log(res);
      });
    },
    wayFormat(k) {
      return this.selectDictLabel(this.wayOptions, k);
    },
    statusFormat(k) {
      return this.selectDictLabel(this.statusOptions, k);
    },
    dealStatus(status) {
      return [0, 2, 1, 3][status];
    },
    //
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 进度条
    next() {
      if (this.active++ > 3) this.active = 0;
    },
    // 上传图片
    handleAvatarSuccess(res, file) {
      //

      console.log("上传图片");
      console.log(res);
      console.log(res.fileName);
      console.log(file);
      this.info.evaluation = res.fileName;
      console.log(this.info.evaluation);
      this.formMess.imageUrl = res.fileName;
      console.log(this.formMess.imageUrl);
    },
    //去上课
    cqcLogin() {
      if(isWeiXin()){
        this.$alert('微信浏览器尚不支持直播课程学习，请使用其他浏览器打开网站登录学习！', '提示', {
          confirmButtonText: '确定',
          callback: action => {
          }
        });
        return null;
      }
      const user = this.$store.getters.user;
      window.open(
        "http://cqc.baikegz.com/login?au=1&us=" +
          user.username +
          "&pw=" +
          user.password +
          "&goto=" +
          "http://cqc.baikegz.com/my/courses/learning",
        "_blank"
      );
      // window.open(
      //   "http://cqc.baikegz.com/login?au=1&us=" +
      //     user.username +
      //     "&pw=" +
      //     user.password,
      //   "_blank"
      // );
    },
    //拷贝快递单号
    copyTrackingNumber() {
      this.$message("单号复制成功");
      // 括号中的内容：对应复制按钮的class
      let clipboard = new ClipboardJS(".copyTrackingNumber");
      clipboard.on("success", function (e) {
        console.log("复制成功");
        //  this.$message('这是一条消息提示')
        //  this.$message('这是一条消息提示');
        // Toast.success("快递单号复制成功");
        e.clearSelection();
      });
      clipboard.on("error", function (e) {
        console.log("复制失败，请重试");
        // Toast.success("复制失败，请重试");
        console.log(e);
      });
    },
    submitForm() {
      if (!this.formMess.imageUrl) {
        this.$message.error("请选择您需要上传的图片");
        return null;
      }
      /* formData格式提交： */
      let formData = new FormData();
      for (var key in this.formMess) {
        formData.append(key, this.formMess[key]);
      }
      console.log(666, "formMess");
      console.log(this.formMess);
      uploadSumit(this.formMess).then((res) => {
        console.log("上传成功888");
        console.log(res);
        // this.$message({
        //   showClose: true,
        //   message: "提交成功~",
        //   type: "success",
        // });
        if (res.code == "200") {
          this.$message({
            showClose: true,
            message: "上传成功~",
            type: "success",
          });
          this.isDisable = true;
          setTimeout(() => {
            this.isDisable = false;
            this.$router.push({ path: "/bm/list" });
          }, 500);
        }
      });
    },
    eCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadingCertificate() {
      //if()
    },
    handleClose(done) {
      done();
      this.dialogVisible = false;
    },
  },
  mounted() {},
};
</script>

<style scoped lang="scss">
.plat-course-jiancha {
  width: 100%;
  height: auto;
  // border: 1px solid tan;
  .pt-line {
    width: 100%;
    height: 26px;
    background: #f8f8f8;
  }
  .pt-line2 {
    width: 100%;
    height: 4px;
    background: #f8f8f8;
  }
  .p-jiancha-box1 {
    width: 95%;
    height: 57px;
    background: #ffffff;
    // box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    span {
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #000000;
    }
  }
  .deit {
    .detail {
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #0048ff;
      cursor: pointer;
    }
    ::v-deep.el-table--border td,
    ::v-deep.el-table--border th,
    ::v-deep.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
      border-right: 1px solid #c2c2c2;
    }
    ::v-deep.el-table td,
    ::v-deep.el-table th.is-leaf {
      border-bottom: 1px solid #c2c2c2;
    }
    ::v-deep.el-table--border:after,
    ::v-deep.el-table--group:after,
    ::v-deep.el-table:before {
      background-color: #c2c2c2;
    }

    .el-table--border,
    .el-table--group {
      border-color: #c2c2c2;
    }

    .el-table td,
    .el-table th.is-leaf {
      border-bottom: 2px solid #c2c2c2;
    }

    .el-table--border th,
    .el-table--border th.gutter:last-of-type {
      border-bottom: 2px solid #c2c2c2;
    }

    .el-table--border td,
    .el-table--border th {
      border-right: 2px solid #c2c2c2;
    }
  }
  .p-jiancha-box2 {
    width: 1239px;
    height: auto;
    margin: 45px auto 0px auto;
    // border: 1px solid tan;
    ::v-deep.el-button {
      background: linear-gradient(
        -54deg,
        rgba(29, 112, 234, 0.89),
        rgba(72, 140, 240, 0.89)
      );
      border-radius: 4px;
    }
    ::v-deep.el-form-item__label {
      text-align: right;
      vertical-align: middle;
      float: left;
      font-size: 14px;
      color: #000;
      line-height: 40px;
      padding: 0 12px 0 0;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
  .invitation-form {
    width: 100%;
    height: 100px;
    .el-form-item {
      margin-bottom: 22px;
      width: 320px;
      float: left;
      margin-right: 80px;
    }
  }
  ::v-deep .el-table th {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #000000;
    background-color: #eaeaea !important;
    // background: #eaeaea;
    font-weight: 500;
  }
  ::v-deep .el-table th > .cell {
    display: inline-block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    font-weight: bold;
    text-align: center;
  }
  .pt-pagination {
    padding: 50px 0px;
    margin-left: 3%;
    display: flex;
    justify-content: center;
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
      color: #ffffff;
      background: rgba(189, 195, 199, 1);
    }
    ::v-deep .el-pagination.is-background .btn-next,
    ::v-deep .el-pagination.is-background .btn-prev,
    ::v-deep .el-pagination.is-background .el-pager li {
      margin: 0 6px;
      // background-color: #f4f4f5;
      // color: #606266;
      min-width: 39px;
      height: 39px;
      border-radius: 2px;
      line-height: 39px;
    }
    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: rgba(17, 99, 235, 1);
      color: #fff;
    }
    ::v-deep .el-pagination button,
    ::v-deep .el-pagination span:not([class*="suffix"]) {
      display: inline-block;
      font-size: 13px;
      min-width: 35.5px;
      height: 39px;
      line-height: 39px;
      vertical-align: top;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
}
::v-deep .el-dialog {
  border-radius: 3%;
  .el-dialog__body {
    position: relative;
  }
  .el-step__title.is-success {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 700;
    color: #27333b;
  }
  .el-step__head.is-success {
    color: #2979ea;
    border-color: #2979ea;
  }
  .el-dialog__headerbtn .el-dialog__close {
    color: #909399;
    width: 35px;
    height: 35px;
    border: 1px solid tan;
    position: absolute;
    top: -29px;
    right: -37px;
    opacity: 0;
    z-index: 99999;
  }
  .class-sucessbox {
    width: 90%;
    height: 600px;
    // border: 1px solid tan;
    margin: 0 auto;
    // position: relative;
    .info-title {
      width: 85%;
      height: auto;
      background: #f2f2f2;
      border-radius: 6px;
      margin: 0 auto;
      display: flex;
      flex-direction: row;
      .info-title_L {
        width: 20%;
        height: auto;
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #27333b;
        text-align: center;
        padding: 27px 0px;
      }
      .info-title_R {
        width: 80%;
        height: auto;
        padding: 22px 0px;
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #27333b;
      }
    }
    .info-progress {
      width: 72%;
      margin: 45px auto;
      .sign-pro-2box-1 {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #a3a4a4;
        padding-top: 5px;
        padding-bottom: 30px;
      }
      .sign-pro-2box-2a {
        font-size: 15px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 25px;
        color: #a3a4a4;
      }
      .sign-pro-2box-2b {
        padding-bottom: 30px;
      }
    }

    img {
      position: absolute;
      right: -1.5%;
      top: -43px;
    }
    .class-top {
      width: 30%;
      height: 65px;
      line-height: 65px;
      text-align: center;
      background: #3d7eff;
      -webkit-box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.3);
      border-radius: 19px;
      position: absolute;
      top: -65px;
      left: 34%;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
    .suc-btn {
      width: 341px;
      height: 52px;
      line-height: 52px;
      text-align: center;
      background: #3d7eff;
      border-radius: 6px;
      font-size: 18px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 25px;
      cursor: pointer;
    }
  }
}
</style>
