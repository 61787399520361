<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置： <router-link tag="a" to="/">首页 </router-link>> 检查员中心
        </div>
        <!-- 平台课程切换列表 -->
        <div class="idx-course-list">
          <div class="idx-course-listbox">
            <!-- <div class="idx-course-list-title">
              <span></span>检查员中心 <span class="idx-s2"></span>
            </div> -->
            <div class="idx-course-tab">
              <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="我的邀请" name="zero">
                  <myInvitation></myInvitation>
                </el-tab-pane>
                <el-tab-pane label="分值明细" name="first">
                  <scoreMingXi></scoreMingXi>
                </el-tab-pane>
                <el-tab-pane label="邀请记录" name="second">
                  <invitedRecord></invitedRecord>
                </el-tab-pane>
                <el-tab-pane label="统计" name="other">
                  <count></count>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myInvitation from "@/views/inspector/components/myInvitation.vue";
import scoreMingXi from "@/views/inspector/components/scoreMingXi.vue";
import invitedRecord from "@/views/inspector/components/invitedRecord.vue";
import count from "@/views/inspector/components/count.vue";
import { listUserCourse } from "@/api/bm/course";
// import { signTeacher, signStudent } from "@/api/bm/student";
export default {
  components: {
    myInvitation,
    scoreMingXi,
    invitedRecord,
    count,
  },
  data() {
    return {
      activeName: "zero",
      selected: -1,
      statusOptions: [
        {
          dictValue: 0,
          dictLabel: "待上传凭证",
        },
        {
          dictValue: 1,
          dictLabel: "待寄出发票",
        },
        {
          dictValue: 2,
          dictLabel: "待确认收款",
        },
        {
          dictValue: 3,
          dictLabel: "已完成",
        },
        {
          dictValue: 4,
          dictLabel: "已驳回",
        },
      ],
      zero: [],
      one: [],
      two: [],
      other: [],
      total: 0,
      wayOptions: undefined,
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    setSelected(selected) {
      this.selected = selected;
    },
    getList() {
      listUserCourse({}).then((res) => {
        this.total = res.total;
        this.dealList(res.rows);
      });
    },
    dealList(rows) {
      let zero = [],
        one = [],
        two = [],
        other = [];
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].status == 0 || rows[i].status == 4) {
          zero.push(rows[i]);
        } else if (rows[i].status == 1) {
          one.push(rows[i]);
          zero.push(rows[i]);
        } else if (rows[i].status == 2) {
          two.push(rows[i]);
          zero.push(rows[i]);
        } else {
          other.push(rows[i]);
          zero.push(rows[i]);
        }
      }
      this.zero = zero;
      this.one = one;
      this.two = two;
      this.other = other;
    },
    wayFormat(k) {
      return this.selectDictLabel(this.wayOptions, k);
    },
    statusFormat(k) {
      return this.selectDictLabel(this.statusOptions, k);
    },
  },
  created() {
    this.getList();
    // this.getSignup();
    this.getDicts("bm_course_way").then((res) => {
      this.wayOptions = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  background: #f8f8f8;
  overflow: hidden;
  // background: linear-gradient(
  //   #dbe7fc 1%,
  //   #ffffff 20%,
  //   #ffffff 20%,
  //   #ffffff 20%,
  //   #ffffff 39%
  // );
  padding-top: 22px;
  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    // text-align: center;
    img {
      width: 1300px;
      height: 450px;
    }
    .idx-course-list {
      width: 1300px;
      height: auto;
      background: #ffffff;
      margin: 40px 0 40px 0;
      padding: 0px;
      // padding: 10px;

      padding-bottom: 10px;
      .idx-course-listbox {
        margin-top: 0px;
        position: relative;
        overflow: hidden;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // align-items: center;
        ::v-deep .el-tabs__header {
          padding: 0;
          position: relative;
          margin: 0px 0px 0px 30px;
          height: 62px;
          // border: 1px solid teal;
        }
        ::v-deep .el-tabs__nav-scroll {
          overflow: hidden;
          margin-top: 14px;
        }
        ::v-deep .el-tabs__item {
          padding: 0px 15px;
          height: 35px;
          line-height: 35px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          background: #dddddd;
          border-radius: 5px;
          color: #3b3b3b !important;
          cursor: pointer;
          margin-right: 30px;
        }
        ::v-deep .el-tabs__nav-wrap::after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 2px;
          background-color: rgba(255, 255, 255, 0);
          z-index: 1;
        }
        ::v-deep .el-tabs__item.is-active {
          text-align: center;

          background: #357eea;
          border-radius: 5px;
          color: #ffffff !important;
        }
        ::v-deep .el-tabs__active-bar {
          background-color: rgba(255, 255, 255, 0) !important;
        }

        ::v-deep .el-tabs__content {
          width: 100% !important;
          height: auto;
          color: #000;
          // border: 1px solid tan;
          // border-top: 1px solid rgba(224, 224, 224, 1);
          margin: 0 auto;
          padding-top: 0px;
        }
        .idx-course-list-title {
          font-size: 23px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #28333c;
          position: absolute;
          top: 1px;

          span {
            width: 5px;
            height: 27px;
            background: #2979ea;
            border-radius: 3px;
            float: left;
            margin-right: 10px;
          }
          .idx-s2 {
            width: 1px;
            height: 21px;
            background: #cccccc;
            float: right;
            margin-left: 52px;
          }
        }
        .idx-course-tab {
          width: 1300px;
          .idx-tab-list {
            width: 260px;
            height: 270px;
            float: left;
            margin-right: 86px;
            margin-top: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            &:hover {
              background: #ffffff;
              box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.1);
              border-radius: 12px 12px 6px 6px;
              transition: all 0.3s linear;
            }
            // border-radius: 12px;
            .idx-tab-list-1 {
              // border: 1px solid teal;
              height: 180px;
              margin-top: 5px;
              position: relative;
              img {
                width: 250px;
                height: 175px;
                border-radius: 12px;
                margin: 0 auto;
                margin-left: 5px;
              }
              .idx-R-1 {
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                margin-bottom: 20px;
                margin-top: 7px;
                padding-left: 16px;
              }
              .idx-R-2 {
                // width: 260px;
                // height: 25px;
                // line-height: 25px;
                // text-align: center;
                // // background: #dfe5e9;
                // border-radius: 3px;
                // font-size: 15px;
                // font-family: Microsoft YaHei;
                // font-weight: 400;
                // color: #808085;
                .idx-R2-s1 {
                  float: left;
                  width: 92px;
                  height: 25px;
                  line-height: 25px;
                  text-align: center;
                  background: #dfe5e9;
                  border-radius: 3px;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #808085;
                  margin-left: 16px;
                }
                .idx-R2-s2 {
                  font-size: 20px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #003cff;
                  line-height: 25px;
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep.el-table .cell {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
    line-height: 23px;
    padding-right: 10px;
    text-align: center;
  }
  ::v-deep.el-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}
</style>
